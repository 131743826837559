<template>
  <main>
    <Row :gutter="15">
      <Col :span="17">
        <ButtonGroup shape="circle">
          <Button icon="md-return-left" shape="circle" @click="goBack">返回</Button>
          <Button icon="md-refresh" @click="loadData">刷新</Button>
          <Button icon="md-add-circle" @click="create" v-if="Auth([ 'UniversityMajor', 'create' ])">添加</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>
    <Divider/>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>
  </main>
</template>

<script>
export default {
  data() {
    return {
      searches: {
        name: "专业名称"
      },
      university_id: this.$route.params.university_id,
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "学校",
          minWidth: 120,
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h("span", params.row.university.name);
          }
        },
        {
          title: "专业",
          key: "name",
          sortable: "custom",
          align: "center",
          minWidth: 200
        },
        {
          title: "添加时间",
          minWidth: 140,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        {
          title: "操作",
          minWidth: 110,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (this.Auth(["UniversityMajor", "update"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "success",
                    size: "small",
                    icon: "md-create"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.update(params.row);
                    }
                  }
                })
              );
            }

            if (this.Auth(["UniversityMajor", "delete"])) {
              html.push(
                h("Button", {
                  props: {
                    type: "error",
                    size: "small",
                    icon: "md-trash"
                  },
                  class: "mg-lr-xs",
                  on: {
                    click: () => {
                      this.remove(params.row);
                    }
                  }
                })
              );
            }

            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/universityMajor/list/university_id/"+this.university_id, { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    create() {
      this.$router.push("/university-major/create/"+this.university_id);
    },
    update(item) {
      this.$router.push("/university-major/update/" + item.id);
    },
    remove(item) {
      this.$Modal.confirm({
        title: "确定要删除该专业吗？",
        content: item.name,
        onOk: () => {
          this.$http.post("/universityMajor/delete/id/" + item.id).then(res => {
            this.loadData();
          });
        }
      });
    },
    goBack(){
      this.$router.push("/university");
    },
  }
};
</script>